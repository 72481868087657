.ficha-paciente-container {
    background-color: #f9f9f9;
    padding: 2rem;
    border-radius: 10px;
  }
  
  .card {
    margin-bottom: 1.5rem;
  }
  
  .card-header {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .card-body {
    font-size: 1rem;
  }
  