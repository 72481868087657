.saldo-paciente-container {
  max-width: 1200px;
  margin: auto;
}

.pagos-realizados, .saldo-pendiente {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.table-responsive {
  overflow-x: auto;
}

.saldo-monto {
  font-weight: bold;
}
