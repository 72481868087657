.tratamiento-paciente-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    color: #333;
  }
  
  form {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  label {
    color: #333;
  }
  
  .table {
    background-color: #ffffff;
  }
  
  .table thead {
    background-color: #c8dcf0;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f0f0f0;
  }
  
  h4 {
    color: #b4c8dc;
  }
  
  button {
    background-color: #c8dcf0;
    border: none;
    color: #333;
  }
  
  button:hover {
    background-color: #b4c8dc;
  }
  