/* Diseño universal para formularios de registro */
body {
    font-family: 'Sans-Serif';
    background-color: #4B4C52;
  }
  
  .registro-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .form-registro {
    background-color: #4B4C52;
    color: #ffffff;
  }

  .form-registro h2 {
    color: #ffffff; 
  }

  .form-registro h4 {
    color: #ffffff;
  }

  .form-registro label {
    color: #ffffff; 
  }
  
  .input-text {
    background-color: #ffffff;
    color: #000000;
    border: none;
    border-radius: 5px;
    padding: 10px;
  }
  
  .input-text:focus {
    outline: none;
    border: 2px solid #50c878;
  }
  
  .checkbox-label {
    color: #ffffff;
  }
  
  .btn-submit {
    background-color: #50c878;
    border: none;
    padding: 10px;
    font-size: 20px;
   
  }
  
  .btn-submit:hover {
    background-color: #4a90e2;
    transition: background-color 0.3s ease;
  }
  
  .invalid-feedback {
    color: #ff4d4d;
  }
  .full-width {
    margin-bottom: 20px;
  }
  
  input, select, button {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }