.dashboard-layout {
  display: flex;
  background-color: #181818;
  color: #ffffff;
}

.dashboard-header {
  display: flex;
  justify-content: space-between; /* Esto asegura que los elementos se distribuyan a ambos lados */
  align-items: center;
  padding: 15px 30px;
  background-color: #1c1c1c;
  color: #ffffff;
  border-bottom: 1px solid #333;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-content h1 {
  display: flex;
  color: #ffffff;
  font-size: 1.8rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logout-btn {
  background-color: #50c878;
  border: none;
  padding: 8px 16px; /* Ajusta el tamaño del botón con menos padding */
  font-size: 0.875rem; /* Tamaño de fuente más pequeño */
  border-radius: 4px; /* Bordes redondeados */
  transition: background-color 0.3s ease;
  margin-left: auto; /* Mantener la separación automática */
  width: auto; /* Asegúrate de que no se expanda a todo el ancho */
  max-width: 150px; /* Limitar el ancho máximo del botón */
}

.logout-btn:hover {
  background-color: #f44336; /* Cambiar el color al hacer hover */
}

.side-menu {
  height: 120vh; /* Altura completa de la ventana */
  background-color: #1c1c1c;
  transition: width 0.3s ease;
  overflow-y: auto; /* Permite el scroll vertical cuando el contenido es demasiado largo */
  overflow-x: hidden; /* Evita que el contenido se desborde horizontalmente */
}

.side-menu::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.side-menu::-webkit-scrollbar-track {
  background-color: #2b2b2b; /* Color de fondo del track */
  border-radius: 10px; /* Bordes redondeados */
}

.side-menu::-webkit-scrollbar-thumb {
  background-color: #50c878; /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Bordes redondeados */
  border: 2px solid #2b2b2b; /* Espacio alrededor del thumb */
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background-color: #4a90e2; /* Color cuando el usuario pasa el mouse por encima */
}

/* Compatibilidad con Firefox */
.side-menu {
  scrollbar-width: thin;
  scrollbar-color: #50c878 #2b2b2b;
}

/* Para todos los demás navegadores (track) */
.side-menu::-moz-scrollbar {
  width: 8px;
}

/* Para todos los demás navegadores (thumb) */
.side-menu::-moz-scrollbar-thumb {
  background-color: #50c878;
  border-radius: 10px;
}

.side-menu.open {
  width: 250px; /* Ancho del menú cuando está abierto */
}

.side-menu.closed {
  width: 80px;
}

.menu-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left:1rem;
  color: white;
  width: 56px; 
  height: 56px;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.menu-text {
  margin-left: 10px; /* Agrega espacio entre el ícono y el texto del menú */
}
.submenu {
  margin-left: 20px; /* Asegura que los submenús estén bien indentados */
}
.submenu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.submenu-item .menu-text {
  margin-left: 10px; /* Agrega espacio entre el ícono y el texto del submenú */
}


.menu-items ul {
  list-style-type: none;
  padding: 0;
  margin-left: 20px;
}

.menu-items li {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.menu-items li:hover {
  background-color: #4A90E2;
}

.submenu li {
  font-size: 1rem;
  padding-left: 20px;
}

.submenu li:hover {
  background-color: #50C878;
}

.main-content {
  flex-grow: 1;
  background-color: #242424;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .side-menu {
    width: 100px;
  }

  .logout-btn {
    font-size: 0.75rem; /* Reducir aún más la fuente en pantallas pequeñas */
    padding: 6px 12px; /* Menos relleno en pantallas pequeñas */
    max-width: 120px; /* Limitar el ancho máximo en pantallas pequeñas */
  }
}
