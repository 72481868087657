.navbar-custom {
    background-color: #ffffff; /* Fondo blanco */
    padding: 1rem 0; /* Espaciado en la parte superior e inferior */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil para el diseño */
    transition: background-color 0.3s ease-in-out; /* Transición suave */
  }
  
  .navbar-logo {
    width: 40px; /* Tamaño del logo */
  }
  
  .nav-link-custom {
    font-size: 1rem;
    font-weight: 500;
    margin: 0 1rem;
    color: #555; /* Color para los enlaces */
    transition: color 0.3s ease, transform 0.3s ease; /* Transiciones suaves */
  }
  
  .nav-link-custom:hover,
  .nav-link-custom:focus {
    color: #007bff; /* Cambia el color al hacer hover o tener foco */
    transform: scale(1.05); /* Aumenta ligeramente el tamaño al pasar el mouse */
  }
  
  /* Estilos para dispositivos móviles */
  @media (max-width: 768px) {
    .navbar-custom {
      text-align: center;
    }
  
    .nav-link-custom {
      margin: 0.5rem 0;
      font-size: 1.1rem;
    }
  
    .navbar-brand {
      margin-bottom: 1rem;
    }
  }
  