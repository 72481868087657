/* Contenedor principal */
.usuarios-registrados {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #80828D;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
  overflow-x: hidden;
}
.table-responsive {
  overflow-x: auto; /* Hace que la tabla sea responsiva */
}


.table-responsive::-webkit-scrollbar {
  height:15px; /* Ancho de la barra de desplazamiento */
}
.table-responsive::-webkit-scrollbar-track {
  background-color: #2b2b2b; /* Color de fondo del track */
  border-radius: 10px; /* Bordes redondeados */
}
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #50c878; /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Bordes redondeados */
  border: 2px solid #2b2b2b; /* Espacio alrededor del thumb */
}
.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #4a90e2; /* Color cuando el usuario pasa el mouse por encima */
}


/* Título de la tabla */
.usuarios-registrados h2 {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
}

/* Tabla de dentistas */
.usuarios-tabla {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  background-color: #80828D;
  border-radius: 8px;
  overflow: hidden; /* Para que el borde de la tabla se vea mejor */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.custom-thead {
  background-color: #44454A !important; /* Cambia el color de fondo */
  color: #ffffff !important; /* Asegúrate de que el color del texto sea blanco */
  text-align: center;
}

.custom-thead th {
  padding: 12px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: inherit; /* Hereda el color de fondo del thead */
}
.usuarios-tabla .custom-thead th {
  background-color: #8B95B6 !important; /* Aplica el color de fondo oscuro */
  color: #ffffff !important; /* Asegura que el texto sea blanco */
  padding: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

/* Estilos para las celdas */
.usuarios-tabla td {
  overflow: hidden;
  text-overflow: ellipsis; /* Agrega puntos suspensivos si el contenido es muy largo */
  white-space: nowrap;
}

.usuarios-tabla tr:nth-child(even) {
  background-color: #f0f0f0; /* Filas con fondo alterno */
}

.usuarios-tabla tr:hover {
  background-color: #e0e0e0; /* Color al pasar el ratón por encima */
}

/* Botones */
button {
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 14px;
}

button.btn-warning {
  background-color: #f0ad4e;
  border: none;
  color: #fff;
  transition: background-color 0.3s ease;
}

button.btn-warning:hover {
  background-color: #ec971f;
}

button.btn-success {
  background-color: #50c878;
  border: none;
  color: #fff;
  transition: background-color 0.3s ease;
}

button.btn-success:hover {
  background-color: #45b565;
}

/* Estilos para el mensaje de éxito */
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Sombra de la tabla al cargar datos */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Altura suficiente para mostrar el spinner */
}

/* Transiciones suaves */
.usuarios-tabla td,
.usuarios-tabla th,
button {
  transition: all 0.3s ease;
}

/* Responsividad */
@media (max-width: 768px) {
  .usuarios-tabla thead {
    display: none; /* Oculta los encabezados de la tabla en pantallas pequeñas */
  }

  .usuarios-tabla tr {
    display: block;
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
  }

  .usuarios-tabla td {
    display: block;
    text-align: right;
    padding: 10px 0;
    border-bottom: 1px solid #dddddd;
    position: relative;
    padding-left: 50%;
  }

  .usuarios-tabla td:before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    text-transform: uppercase;
    color: #4B4C52;
  }
}

/* Fondo gris para el cuerpo del modal */
.modal-content {
  /* Fondo gris claro */
}

/* Resaltar los labels con negrita */
.modal-body .form-label {
  font-weight: bold; /* Resaltar los labels en negrita */
}
