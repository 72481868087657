.resumen-ficha-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  .search-bar {
    width: 100%;
    border: 1px solid #b4c8dc;
    border-radius: 4px;
  }
  
  .list-group-item {
    background-color: #f0f0f0;
    border: 1px solid #c8dcf0;
    color: #333;
  }
  
  .list-group-item:hover {
    background-color: #c8dcf0;
    cursor: pointer;
  }
  
  .patient-navbar .nav-link {
    color: #333;
    font-weight: bold;
    margin: 0 10px;
  }
  
  .patient-navbar .nav-link:hover {
    color: #b4c8dc;
  }
  
  @media (max-width: 768px) {
    .resumen-ficha-container {
      padding: 10px;
    }
  
    .search-bar {
      margin-bottom: 10px;
    }
  }
  