.citas-container,{
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #80828D;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
  overflow-x: hidden;
  }
  
  .form-control {
    background-color: #ffffff;
    border-color: #c8dcf0;
    color: #333;
  }
  
  .form-control:focus {
    border-color: #b4c8dc;
    box-shadow: none;
  }
  
  button {
    background-color: #b4c8dc;
    border-color: #b4c8dc;
  }
  
  button:hover {
    background-color: #c8dcf0;
    border-color: #c8dcf0;
  }
  